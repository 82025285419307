body {
  background-image: url('/assets/layout/images/main-bg.png');
  background-repeat: no-repeat;
  background-color: #eeeeee;
  background-attachment: fixed;
}

body .ui-fieldset {
  padding: 10px;
  font-size: 16px;
  color: #343434;
}

body .ui-fieldset-legend {
  font-size: 18px;
  font-weight: bold;
}

body .ui-datatable {
  font-size: 16px;
}

body .ui-datatable-header {
  font-weight: bold;
}

body .ui-widget-header {
  background-color: #4385b4;
  border-color: #4385b4;
}

body .ui-tooltip .ui-tooltip-text {
  background-color: #555555;
}
body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #555555;
}
body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #555555;
}
body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #555555;
}
body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #555555;
}

body .ui-tooltip .ui-tooltip-text {
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
}

.splash-screen {
  background-color: #1c6496;
}

.card {
  border-radius: 3px;
}

.alert-card {
  border-radius: 3px;
  border: 0;
  margin-top: 10px;
  -webkit-filter: drop-shadow(0px 1px 1px gray);
          filter: drop-shadow(0px 1px 1px gray);
}

.alert-card-group {
  padding: 0;
  border: 0;
  -webkit-filter: none;
          filter: none;
  background-color: #cccccc;
  border-radius: 3px;
}

.alert-card-unit {
  border: 0;
  margin: 0;
  padding: 5px;
  padding-bottom: 10px;
  -webkit-filter: drop-shadow(0px 2px 3px #bbbbbb);
          filter: drop-shadow(0px 2px 3px #bbbbbb);
}

.alert-card-unit div:not([class*='react-select']) {
  padding: 0;
}

.alert-row {
  font-size: 16px;
}

.report-card {
  border-radius: 3px;
  border: none;
  margin: 10px;
  -webkit-filter: drop-shadow(0px 1px 1px gray);
          filter: drop-shadow(0px 1px 1px gray);
}

.card-header1 {
  padding: 10px;
  background-image: url('/assets/layout/images/header-bg1.png');
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
  border-bottom: 4px solid #ce8351;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-filter: drop-shadow(0px 1px 1px #777777);
          filter: drop-shadow(0px 1px 1px #777777);
}

.card-group-header {
  margin: 0;
  margin-top: 5px;
  padding: 5px;
  background-color: #4385b4;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 100px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 4px solid #cccccc;
  border-top: 1px solid #2d5594;  
  -webkit-filter: drop-shadow(0px 1px 1px #aaaaaa);  
          filter: drop-shadow(0px 1px 1px #aaaaaa);
}

.card-group-content {
  background-image: url('/assets/layout/images/group-bg.png');
  background-repeat: no-repeat;
  background-color: #fbfbfb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 5px;  
}

.card-alert-header {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #555555;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #ce8351;
}

.subtitle {
  color: #555555;
  font-style: italic;
  font-size: 13px;
}

.unit-groups-fs {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.material-icons {
  color: #cccccc;
  cursor: default;
}

.action-icons {
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 1px 1px gray);
          filter: drop-shadow(0px 1px 1px gray)
}

.geofence-list-header {
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.geofence-list-item {
  padding: 5px;
  margin: 5px;
  margin-bottom: 10px;
  margin-right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  -webkit-filter: drop-shadow(0px 1px 1px gray);
          filter: drop-shadow(0px 1px 1px gray);  
}

#slide {
  position: relative;
  left: 400px;
  opacity: 0;  
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0s;
  -webkit-transition: opacity 1s;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
  transition: opacity 1s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
    opacity: 1.0;
  }
}

@keyframes slide {
  100% {
    left: 0;
    opacity: 1.0;
  }
}

.geofence-map {
  
}

.right-align {
  text-align: right;
}

.login-panel {
  background-color: #ffffff;
  -webkit-filter: drop-shadow(0px 1px 1px gray);
          filter: drop-shadow(0px 1px 1px gray);
}

.layout-container .layout-menu .profile {
  height: 90px;
  font-size: 18px;
  font-weight: bold;
  color: #222222;
  background-color: #f8f8f8;
}

.layout-container .topbar {
  background-color: #4385b4;
}

.layout-container .topbar .topbar-right #menu-button {
  background-color: #ce8351;
}

.layout-container .topbar .topbar-left {  
  background-color: #1c6496;
}

.layout-container .ultima-menu li {
  font-size: 18px;
  color: #222222;
}

.layout-container .ultima-menu li > a i {
  color: #cccccc;
}

.layout-container .ultima-menu li a:hover {
  color: #2d5594;
  background-color: #ecf4ff;
}

.layout-container .ultima-menu li > a:hover i {
  color: #51ce9e;
}

.layout-container .ultima-menu li.active-menuitem > a {
  color: #2d5594;
  background-color: #ecf4ff;
  border-left: 10px solid #2d5594;
  font-weight: bold;
}

.layout-container .ultima-menu li.active-menuitem > a i {
  color: #51ce9e;
}

.layout-container .topbar-items > li > a .topbar-badge {  
  background-color: #5180ce;
}

.layout-container .layout-rightpanel.layout-rightpanel-active {
  right: 0;
  -webkit-transition-timing-function: cubic-bezier(0, 0.1, 0, 0.7);
  transition-timing-function: cubic-bezier(0, 0.1, 0, 0.7);
  z-index: 100; 
}

.float-left {
  float: left;
}

.center {
  text-align: center;
  vertical-align: middle;
}

.toolbar-icon {
  color: #cccccc;
  cursor: pointer;
  font-size: 40;
}

.toolbar-icon-active {
  color: #51ce9e;
}

.select-geofence {
  float: left;
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.geofence-alert-tag {
  width: 150px;
  font-size: 9;
  color: #ffffff;
  background-color: #4385b4;
  padding: 3px;
  margin: 1px;
  border-radius: 3px;
}

.geofence-alert-tag i { 
  color: #ffffff;
}

.vertical-divider {
  float: left;
  width: 1px;
  height: 100px;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #dfdfdf;
}

.contact-form {
  padding: 0;
}

.contact-form div {
  padding: 0;
}

@-webkit-keyframes ui-progress-spinner-color {
  100%,
  0% {
      stroke: #cccccc;
  }
  40% {
      stroke: #51ce9e;
  }
  66% {
      stroke: #ce8351;
  }
  80%,
  90% {
      stroke: #2d5594;
  }
}

@keyframes ui-progress-spinner-color {
  100%,
  0% {
      stroke: #cccccc;
  }
  40% {
      stroke: #51ce9e;
  }
  66% {
      stroke: #ce8351;
  }
  80%,
  90% {
      stroke: #2d5594;
  }
}

.p-calendar .p-datepicker {
  z-index: 999
}

.ui-scrollpanel-content {
  width: 100%
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
cursor: not-allowed;
}
.Resizer.disabled:hover {
border-color: transparent;
}

.geofenceResponsive {
	height: calc(100vh - 175px);
}

@media only screen
and (min-device-width : 320px) 
and (max-device-width : 480px) { 
	.geofenceResponsive {
		height: auto;
	}
}

.dropdownButton {
  height: 32px;
  border-radius: 3px;
  background-color: #51ce9e;
  color: white;
  -webkit-filter: drop-shadow(0px 1px 2px #aaa);
          filter: drop-shadow(0px 1px 2px #aaa);
  padding: 5px;
}

@media only screen
and (min-device-width : 320px) 
and (max-device-width : 480px) { 
	.geofenceAlertResponsiveHeader {
		display: none;
	}
}

.map-marker-popup .leaflet-popup-content {
  margin: 8px 10px !important;
}

.map-card .ui-card-body {
  padding: 0px !important;
}
